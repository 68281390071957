@tailwind base;
@tailwind components;
@tailwind utilities;

[contenteditable] {
  outline: 0px solid transparent;
}

@import './packages';
@import './components';
@import './animations';

html {
  scroll-behavior: smooth;
  font-family: 'Inter', sans-serif;
}

.highlightedElement {
  p {
    opacity: 0;
  }
}
.with-highlight-step-change {
  .highlightedElement {
    outline: 0;
    p {
      opacity: 1;
    }
  }
  & ~ :not(#quick-edit-container) {
    display: none !important;
  }
}


#editor-smart-tag-content {
  background: #f5f5f5;
  border-radius: 4px;
  padding: 2px 8px 2px 3px;
  margin: 0 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.readonly {
  cursor: not-allowed !important;

  & * {
    pointer-events: none !important;
  }

  &-opacity {
    opacity: 0.75 !important;
  }
}
.tag-list-portal {
  @media only screen and (max-width: 1440px) {
    zoom: 0.8;
  }
}

.templateSlider {
  max-width: 100% !important;
  width: 100%;

  .swiper-wrapper {
    padding: 100px 0px;
    align-items: center;
  }
  .swiper-slide {
    transform-origin: right;
    transform: scale(1);

    &.swiper-slide-prev .side {
      transform-origin: right;
      transform: scale(0.8);
      transition: 0.2s;
      pointer-events: none;
      user-select: none;
    }
    &.swiper-slide-active {
      transform: scale(1);
      z-index: 2;
    }
    &.swiper-slide-next .side {
      zoom: 0.8;
      transition: 0.2s;
      margin-top: auto;
      margin-bottom: auto;
      pointer-events: none;
      user-select: none;
    }
  }
}

// React-toastify

:root {
  --toastify-toast-width: auto;
}
.Toastify__toast {
  width: 320px;
}
